<template>
  <aside
    v-if="categories.length > 0"
    class="g-catalogo__categorias"
    :class="toggleCategory ? 'show-category' : ''"
  >
    <p class="g-catalogo__categorias__head">Categorías</p>

    <tree-menu :categories="categories"></tree-menu>
  </aside>

  <aside v-else class="g-catalogo__categorias">
    <h4 class="g-catalogo__categorias__head">Categorías</h4>
    <div class="bbsk bbsk--h5" style="width: 50%"></div>
    <div class="bbsk bbsk--h5" style="width: 70%"></div>
    <div class="bbsk bbsk--h5" style="width: 30%"></div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import TreeMenu from "./TreeMenu";

export default {
  name: "CatalogueCategoriesNav",
  components: {
    TreeMenu,
  },
  computed: {
    ...mapGetters({
      categories: "categories",
      toggleCategory: "toggleCategory",
    }),
  },
};
</script>
