import {mapGetters} from "vuex";

export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      selectedCategory: "selectedCategory",
    }),
  },
}