<template>
  <div class="w-full" v-if="categories">
    <tree-menu-desktop v-if="desktop" :categories="categories"/>
    <tree-menu-mobile v-else :categories="categories"/>
  </div>
</template>

<script>
import TreeMenuDesktop from "./TreeMenuDesktop";
import TreeMenuMobile from "./TreeMenuMobile";
import ResponsiveUtilities from "../../../Mixins/ResponsiveUtilities";

export default {
  name: "tree-menu",
  mixins: [ ResponsiveUtilities ],
  components: {
    TreeMenuDesktop,
    TreeMenuMobile,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
};
</script>