import {mapGetters} from "vuex"

export default {
  computed: {
    ...mapGetters({
      productFilters: 'productFilters',
      retailers: 'retailers'
    })
  },
  methods: {
    applyFilters(parameters, featured) {
      this.productFilters?.forEach((filter) => {
        if (filter.name === 'price') {
          parameters.minPrice = filter.values.minPrice
          parameters.maxPrice = filter.values.maxPrice
        }
        if (filter.name === 'brand') {
          parameters.brand = filter.values.brands
        }
        if (filter.name === 'rating') {
          parameters.minRating = filter.values.minRating
          parameters.maxRating = filter.values.maxRating
        }
        if (filter.name === 'retailer') {
          parameters.retailerId = filter.values.retailers.map((retailer) => {
            return this.$getKeyByValue(this.retailers,retailer)
          })
        }
        if (filter.name === 'tag') {
          parameters.tags  = filter.values.tags
        }
        if(featured)
        {
          parameters.minPriority = 90;
        }
      })
      return parameters
    }
  }
}
