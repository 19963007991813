<template>
  <section class="g-productos" v-if="!loadingProducts">
    <product-item v-for="(product, index) in products" :key="index" :product="product">
    </product-item>
    <!-- Mostrar nova card (descomentar) -->
    <!-- <product-item-next
      v-for="(product, index) in products"
      :key="index"
      :product="product"
    > 
    </product-item-next> -->
    <div class="g-productos__actions">
      <button
        type="button"
        class="button button--primary"
        @click="infiniteHandler()"
        v-if="lastPage === false && verMasNotNeeded === false"
      >
        {{ loading ? "Cargando" : "Ver más" }}
      </button>
    </div>
  </section>
  <section class="g-productos" v-else>
    <ProductItemSkeleton></ProductItemSkeleton>
    <ProductItemSkeleton></ProductItemSkeleton>
    <ProductItemSkeleton></ProductItemSkeleton>
    <ProductItemSkeleton></ProductItemSkeleton>
  </section>
</template>

<script>
import ProductItem from "../../../components/ProductItem";
import ProductItemNext from "../../../components/ProductItemNext";
import ProductItemSkeleton from "../../../components/skeleton/ProductItemSkeleton";
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";

export default {
  name: "CatalogueProducts",
  components: {
    ProductItem,
    ProductItemSkeleton,
    ProductItemNext,
  },
  props: {
    getter: {
      type: String,
      default: "products",
    },
    route: {
      type: Object,
      required: false,
    },
    parameters: {
      type: Object,
      required: false,
    },
    infinite: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      loadingProducts: "loadingProducts",
    }),
    products() {
      return this.$store.getters[this.getter];
    },
    verMasNotNeeded() {
      return this.products?.length < 40;
    },
  },
  data() {
    return {
      currentPage: 0,
      identifier: uuidv4(),
      loading: false,
      lastPage: false,
    };
  },
  methods: {
    async infiniteHandler($state) {
      this.loading = true;
      this.currentPage = Math.floor(this.products.length / 40 + 1);
      const parameters = { ...this.parameters, ...{ page: this.currentPage } };
      if (this.products.length >= 40) {
        try {
          const previousLength = this.products.length;
          await this.$store.dispatch("getProductsScroll", parameters);
          if (
            previousLength === this.products.length ||
            this.products.length % 40 === 0
          ) {
            this.loading = false;
            $state.loaded();
          } else {
            this.lastPage = true;
            this.loading = false;
          }
        } catch (error) {
          console.log(error);
        }
      } else if (parseInt(this.products.length) === 0) {
        this.resetInfiniteLoader();
      } else {
        //$state.complete();
      }
    },
  },
};
</script>
