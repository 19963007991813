<template>
  <div class="header-catalogue">
    <div class="bb-container">
      <div class="header-catalogue__grid">
        <div class="header-catalogue__left">
          <categories-btn/>
        </div>
        <div
            class="header-catalogue__right"
            @click="toggleSearch = !toggleSearch"
            :class="toggleSearch ? 'toggleSearch' : ''"
        >
          <div class="header-catalogue__action">
            <img
                v-if="toggleSearch"
                loading="lazy"
                src="../../../assets/img/ico/ico-close-square.svg"
                alt=""
            />
            <img v-else src="../../../assets/img/ico/ico-search.svg" alt="" />
          </div>
        </div>
      </div>
      <transition name="fade">
        <div v-if="toggleSearch" class="header-catalogue__search">
          <CatalogueSearchBar></CatalogueSearchBar>
        </div>
      </transition>
    </div>
  </div>

</template>

<script>
import CategoriesBtn from "../../../components/CategoriesBtn";

export default {
  name:'CatalogueHeader',
  data() {
    return {
      toggleSearch: false,
    };
  },
  components: {
    CategoriesBtn,
    CatalogueSearchBar: () =>
        import(/* webpackChunkName: "CatalogueSearchBar" */ /* webpackPrefetch: true */ "../../catalogue/components/CatalogueSearchBar"),
  },
}
</script>
