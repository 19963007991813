export default {
  methods: {
    getRouteToCategory(category) {
      return {
        name: 'category',
        params: {
          slug: category.slug,
        },
      }
    },
    async redirectTo(category) {
      await this.$router.push(this.getRouteToCategory(category))
    },
    async toggle(category) {
      this.$store.commit("saveSelectedCategory", category);
      await this.redirectTo(category)
    },
  }
}