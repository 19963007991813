<template>
  <div class="mobile-category" v-if="toggleCategory">
    <transition name="slide-fade">
      <div class="category-list">
        <div class="category-list__header">
          <div class="category-list__label">Catálogo</div>
          <div @click.stop="$store.commit('setToggleCategory', false)" class="close-btn">
            <span></span><span></span>
          </div>
        </div>

        <div class="category-list__subheader">Nuestro catalogo</div>

        <!-- CATEGORIES PRINCIPALS-->
        <div v-for="(category, i) in categories" :key="i" class="category-item">
          <p @click="dropDownSubCategories(i)" class="category-item__content">
            <a :href="categoryLink(category)" @click.prevent="" class="color: inherit; text-decoration: inherit;">{{ category.name }}</a>
            <img
              :src="require('../../../assets/images/icons/right-arrow.png')"
              alt="right-arrow"
            />
          </p>
        </div>
      </div>
    </transition>
    <transition name="slide-fade">
      <template v-if="currentSubCategoryActive === -1">
        <div
          v-if="currentActiveCategory !== -1"
          class="subcategory-mobile-menu category-list"
        >
          <div class="category-list__header">
            <div
              @click="currentActiveCategory = -1"
              ref="catBtn"
              class="category-list__label"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-arrow-narrow-left"
                width="35"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="5" y1="12" x2="9" y2="16" />
                <line x1="5" y1="12" x2="9" y2="8" />
              </svg>
              {{ activeCategoryName }}
            </div>
            <div @click="currentActiveCategory = -1" class="close-btn">
              <span></span><span></span>
            </div>
          </div>
          <div>
            <div
              class="category-list__subheader category-list__subheader--yellow"
              @click="go(activeCategory)"
            >
              <span> Ver toda la categoria "{{ activeCategoryName }}" </span>
              <img
                :src="require('../../../assets/images/icons/right-arrow.png')"
                class="md:hidden ml-1 w-3"
                alt="arrow"
              />
            </div>

            <template v-for="(subCat, j) in activeCategory.subCategory">
              <div
                :key="j"
                v-if="subCat.subCategory.length > 0"
                @click="currentSubCategoryActive = subCat"
                class="category-item"
              >
                <div class="category-item__content">
                  <a :href="categoryLink(subCat)" @click.prevent="" class="color: inherit; text-decoration: inherit;">{{ subCat.name }}</a>
                  <img
                    :src="require('../../../assets/images/icons/right-arrow.png')"
                    class="md:hidden ml-1 w-3"
                    alt="arrow"
                  />
                </div>
              </div>
              <div
                v-if="subCat.subCategory.length === 0"
                class="category-item"
                :key="j"
                @click="toggleCat(subCat)"
              >
                <div class="category-item__content">
                  <a :href="categoryLink(subCat)" @click.prevent="" class="color: inherit; text-decoration: inherit;">{{ subCat.name }}</a>
                  <img
                    src="../../../assets/images/icons/right-arrow.png"
                    class="md:hidden ml-1 w-3"
                    alt="arrow"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <div v-else class="subcategory-mobile-menu category-list">
        <div class="category-list__header">
          <div
            @click="currentSubCategoryActive = -1"
            ref="catBtn"
            class="category-list__label"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-arrow-narrow-left"
              width="35"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="5" y1="12" x2="19" y2="12" />
              <line x1="5" y1="12" x2="9" y2="16" />
              <line x1="5" y1="12" x2="9" y2="8" />
            </svg>
            {{ activeCategory.name }}
          </div>
          <div @click="currentSubCategoryActive = -1" class="close-btn">
            <span></span><span></span>
          </div>
        </div>
        <div>
          <div
            class="category-list__subheader category-list__subheader--yellow"
            @click="go(activeSubcategory)"
          >
            <span> Ver toda la subcategoria "{{ activeSubCategoryName }}" </span>
            <img
              :src="require('../../../assets/images/icons/right-arrow.png')"
              class="md:hidden ml-1 w-3"
              alt="arrow"
            />
          </div>

          <template v-for="(subCat, j) in activeSubcategory.subCategory">
            <div
              :key="j"
              class="category-item"
              @click="
                toggleCat(subCat)
              "
            >
              <div class="category-item__content">
                <span>{{ subCat.name }}</span>
                <img
                  src="../../../assets/images/icons/right-arrow.png"
                  class="md:hidden ml-1 w-3"
                  alt="arrow"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import TreeMenuCategories from "./mixins/TreeMenuCategories";
import TreeMenuToggle from "./mixins/TreeMenuToggle";
import { mapGetters } from "vuex";
import GotoCategory from "./mixins/GotoCategory";
import CategoryLink from "./mixins/CategoryLink";

export default {
  name: "TreeMenuMobile",
  mixins: [TreeMenuCategories, TreeMenuToggle, GotoCategory, CategoryLink],
  data() {
    return {
      currentActiveCategory: -1,
      currentSubCategoryActive: -1,
    };
  },
  computed: {
    ...mapGetters({
      toggleCategory: "toggleCategory",
    }),
    activeCategory() {
      return this.categories[this.currentActiveCategory];
    },
    activeCategoryName() {
      return this.activeCategory?.name;
    },
    activeSubcategory() {
      return this.activeCategory?.subCategory.find(
        (subCategory) => subCategory.id === this.currentSubCategoryActive.id
      );
    },
    activeSubCategoryName() {
      return this.activeSubcategory?.name;
    },
  },
  methods: {
    close() {
      this.$store.commit('setToggleCategory', false);
    },
    toggleCat(category) {
      this.close()
      this.toggle(category)
    },
    go(category) {
      this.close()
      this.gotoCategory(category)
    },
    dropDownSubCategories(i) {
      if (this.currentActiveCategory === -1) {
        this.currentActiveCategory = i;
      } else if (this.currentActiveCategory !== -1 && this.currentActiveCategory === i) {
        this.currentActiveCategory = -1;
      } else {
        this.currentActiveCategory = i;
      }
    },
  },
};
</script>
