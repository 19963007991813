<template>
  <ul class="category-list desktop-category">
    <li
        class="desktop-category__item"
        v-for="(category, index) in categories"
        :key="index"
    >
      <!--      CATEGORY LEVEL-->
      <a
          :href="categoryLink(category)"
          @click.prevent="toggle(category)"
          style="color: inherit;"
          class="desktop-category__item__label desktop-category__item__label--parent"
          :class="{ 'desktop-category__item__active' : isActiveCategory(category) }"
      >
        {{ category.name }}
      </a>
      <div
          :id="category.id"
          class="desktop-category__subcategory"
          v-if="showSubcategories(category)"
      >
        <div
            class="acordio- desktop-category__item"
            v-for="(subcategory1, index) in category.subCategory"
            :key="index"
        >
          <!--          SUBCATEGORY LEVEL-->
          <a
              :href="categoryLink(subcategory1)"
              @click.prevent="toggle(subcategory1)"
              class="desktop-category__item__label"
              :class="{ 'desktop-category__item__active' : isActiveCategory(subcategory1) }"
              :ref="subcategory1.name"
          >{{ subcategory1.name }}</a
          >
          <div
              :id="subcategory1.id"
              :data="'0_2' + subcategory1.name"
              class="desktop-category__subcategory"
              v-if="showSubcategories(subcategory1)"
          >
            <div
                class="acordio- desktop-category__item"
                v-for="(subcategory2, index) in subcategory1.subCategory"
                :key="index"
            >
              <!--          SUB_SUBCATEGORY LEVEL-->
              <a
                  :href="categoryLink(subcategory2)"
                  @click.prevent="toggle(subcategory2)"
                  style="color:inherit;"
                  class="acordio- desktop-category__item__label"
                  :class="{ 'desktop-category__item__active' : isActiveCategory(subcategory2) }"
                  :ref="subcategory2.name"
              >{{ subcategory2.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import TreeMenuCategories from "./mixins/TreeMenuCategories";
import CategoryLink from "./mixins/CategoryLink";
import TreeMenuToggle from "./mixins/TreeMenuToggle";
import {mapGetters} from "vuex";

export default {
  name: "TreeMenuDesktop",
  mixins: [ TreeMenuCategories, CategoryLink, TreeMenuToggle],
  computed: {
    ...mapGetters({
      breadcrumb: "breadcrumb",
    }),
  },
  methods: {
    isActiveCategory(category) {
      return this.selectedCategory?.slug === category.slug
    },
    showSubcategories(category) {
      if (category.subCategory?.length > 0) {
        if (this.selectedCategory?.slug === category.slug) return true;
        if (this.breadcrumb?.length > 0) {
          if (this.breadcrumb[0].slug === category.slug) return true;
        }
        if (this.breadcrumb?.length > 1) {
          if (this.breadcrumb[1].slug === category.slug) return true;
        }
      }
      return false
    },
  },
}
</script>