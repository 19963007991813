<template>
  <div class="bb-product-container" v-bind:class="containerStyle">
    <a class="bb-product" :href="productUrl" @click.prevent="showProduct()">
      <!-- TODO: Images responsive nou -->
      <picture class="image-quadrat-cont bb-product__picture">
        <span class="bb-product__discount"> -55% </span>
        <div class="bb-product__overlay"></div>
        <source :srcset="getImgUrl(130)" media="(max-width: 360px)" />
        <source :srcset="getImgUrl(150)" media="(max-width: 767px)" />
        <img loading="lazy" :src="getImgUrl(200)" class="image-quadrat" :alt="name" />
      </picture>

      <div class="bb-product__content" style="position: relative">
        <div class="bb-product__brand" v-html="product.brand"></div>
        <h3 class="bb-product__name" v-html="name"></h3>
        <div>
          <div class="bb-product-price">
            <!-- <div class="bb-product-price__range" v-if="differentPricing">
              {{ $utils.formatMoney(product.minPrice) }} <span>a</span>
              {{ $utils.formatMoney(product.maxPrice) }}
            </div>
            <div class="bb-product-price__range" v-if="!differentPricing">
              {{ $utils.formatMoney(product.minPrice) }}
            </div> -->

            <div class="bb-product-price__sale">
              <!-- <span class="bb-product-price__discount"> -55% </span> -->
              <div class="bb-product-price__pricing">
                <ins>{{ $utils.formatMoney(product.maxPrice) }}</ins>
                <del>{{ $utils.formatMoney(product.minPrice) }}</del>
              </div>
            </div>
          </div>

          <div>
            <div :id="retailersId" class="t-retailer">
              <div
                v-for="(retailer, index) in product.productRetailers"
                :class="
                  't-retailer__item t-retailer__item--' + retailers[retailer.retailerId]
                "
                :key="index"
              >
                <img
                  :id="'product_' + product.id + '_retailer_' + retailer.id"
                  :src="$getLogoRetalier(retailers[retailer.retailerId])"
                  width="30"
                  height="30"
                  :class="
                    't-retailer__logo t-retailer__logo--' + retailers[retailer.retailerId]
                  "
                  :alt="retailers[retailer.retailerId]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>

    <product-added-to-list-modal
      v-if="showProductAddedToList"
      @cancel="cancelProductAddedToList"
    />

    <product-login-to-access-your-list-modal
      v-if="showLoginModal"
      @cancel="cancelLoginModal"
      @afterLogin="addProductToList"
    />
  </div>
</template>

<script>
import NoSSR from "vue-no-ssr";
import StarRating from "vue-star-rating";
import { mapGetters } from "vuex";

export default {
  name: "ProductItemNext",
  components: {
    StarRating,
    "no-ssr": NoSSR,
    ProductAddedToListModal: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ProductAddedToListModal" */ "../modules/product/components/ProductAddedToListModal"
      ),
    ProductLoginToAccessYourListModal: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ProductLoginToAccessYourListModal" */ "../modules/product/components/ProductLoginToAccessYourListModal"
      ),
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
    containerStyle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showProductAddedToList: false,
      showLoginModal: false,
    };
  },
  computed: {
    ...mapGetters({
      retailers: "retailers",
      isAuthenticated: "isAuthenticated",
      myList: "myList",
    }),
    retailersId() {
      return "product_" + this.product.id + "_retailers";
    },
    productUrl() {
      var res = this.$router.resolve(this.to).href;
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === "production") return "https://www.hellobb.net" + res;
      return res;
    },
    to() {
      var _name = "product";
      var _url = this.brandUrl;
      if (this.product.metaInfo?.title) {
        _name = "product-new";
        _url = this.nameUrl;
      }
      let to = {
        name: _name,
        params: { name: _url, brand: _url, id: this.product.id },
      };
      if ("filters" in this.$route.query || "sort" in this.$route.query) {
        to.query = {
          back: true,
        };
      }
      return to;
    },
    isProductSaved() {
      if (this.isAuthenticated) {
        return this.$store.state.savedProducts.find(
          (savedProducts) => savedProducts === this.product.listProductId
        );
      } else {
        return false;
      }
    },
    text() {
      return this.isProductSaved ? "&#x2714;" : "+";
    },
    brandUrl: function () {
      if (this.product.url) {
        return this.$formatUrlhelloBB(this.product.url);
      } else {
        return this.$formatUrlhelloBB(this.product.brand);
      }
    },
    nameUrl: function () {
      return this.$formatUrlhelloBB(this.product.metaInfo?.title);
    },
    differentPricing: function () {
      return parseInt(this.product.minPrice) !== parseInt(this.product.maxPrice);
    },
    name() {
      return this.product.name?.length > 80
        ? this.product.name.substring(0, 80) + "..."
        : this.product.name;
    },
  },
  methods: {
    cancelProductAddedToList() {
      this.showProductAddedToList = false;
      document.body.style.overflowY = "";
    },
    cancelLoginModal() {
      this.showLoginModal = false;
      document.body.style.overflowY = "";
    },
    async addProductToList() {
      this.$store.commit("toggleSaveProduct", this.product);
      return await this.$store.dispatch("addProductToList", {
        list: this.myList,
        product: this.product,
      });
    },
    getImgUrl() {
      // eslint-disable-next-line no-undef
      return process.env.URL_IMG + this.product.photo;
    },
    getImgUrl(size) {
      // eslint-disable-next-line no-undef
      if (this.product.photo.startsWith("https://cdn.shopify.com"))
        return this.product.photo;
      return (
        process.env.URL_IMG + this.product.photo + `?tr=w-${size},h-${size},cm-pad_resize`
      );
    },
    async showProduct() {
      try {
        await this.$router.push(this.to);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
