<template>
  <div class="productsk">
    <div class="bbsk bbsk--image"></div>
    <div class="productsk__content">
      <div class="bbsk bbsk--h6" style="width: 40%"></div>
      <div class="bbsk bbsk--h5" style="width: 90%"></div>
      <div class="bbsk bbsk--h6" style="width: 30%"></div>
      <div class="bbsk bbsk--retailer"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItemSkeleton",
};
</script>

<style lang="scss" scoped></style>
